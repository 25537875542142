.hero {
    width: 100%;
    height: calc(100vh - #{$header-height * 1.8});
    padding: $padding * 1.5 $padding * 1.5 0;
    position: relative;

    &__image {
        width: 100%;
        max-width: 29rem;
        margin: 0 auto;
    }

    &__down {
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        background: url(../img/arrow-down.svg) center center no-repeat;
        background-size: 1.5rem auto;
        position: absolute;
        bottom: $padding * 2;
        left: 50%;
        transform: translateX(-50%);

        &:focus {
            outline: none;
        }
    }

    #hero-svg {
        overflow: visible;
    }

    #first,
    #second,
    #third {
        opacity: 0;
        animation: fadeInUp 0.75s cubic-bezier(0.38, 0.73, 0.41, 0.97) forwards;
    }

    #first {
        animation-delay: 1s;
    }

    #second {
        animation-delay: 2s;
    }

    #third {
        animation-delay: 3s;
    }

    @include respond-min($size-medium) {
        height: auto;
        padding: $padding * 3.75;
        padding-bottom: $padding * 9.75;

        &__image {
            width: 29rem;
            margin: 0 auto;
        }

        &__down {
            bottom: $margin * 4.875;
        }
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
