.training-cta {
    width: 100%;
    background-color: $white;
    padding: $padding * 3.75 $padding * 2.625 $padding * 2;
    text-align: center;

    h1 {
        color: $brand-color;
    }

    .button {
        margin-top: $margin * 2;
    }

    @include respond-min($size-medium) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $padding * 8.75 0;

        p {
            width: 42.5rem;
        }

        h1 {
            font-size: 3.5rem;
        }
    }
}
