body {
    background-color: $white-off;
    padding-bottom: $header-height;

    * {
        box-sizing: border-box;
    }

    @include respond-min($size-medium) {
        padding-bottom: 0;
    }
}

img {
    max-width: 100%;
    height: auto;

    &.alignleft {
        float: left;
        margin-right: $margin * 1.5;
        margin-bottom: $margin * 1;
    }

    &.alignright {
        float: right;
        margin-left: $margin * 1.5;
        margin-bottom: $margin * 1;
    }

    &.aligncenter {
        float: none;
        margin: 0 auto;
        display: block;
        margin-bottom: $margin * 1;
    }
}

svg {
    max-width: 100%;
}

.page {
    body & {
        padding-bottom: $padding;
    }

    &__container {
        width: 100%;
        padding: 0 $padding;

        &--full {
            padding: 0;
        }
    }

    &__content,
    &__sidebar {
        * + & {
            margin-top: $margin;
            margin-bottom: $margin * 2;
        }
    }

    @include respond-min($size-medium) {
        body & {
            padding-bottom: $padding * 6.5;
        }

        &__container {
            margin: 0 auto;

            &--full {
                padding: $padding;
            }
        }

        &__two-col {
            display: flex;
            align-items: flex-start;
        }

        &__content {
            width: 48.75rem;
        }

        &__sidebar {
            width: 21rem;

            > * + > * {
                margin-top: $margin * 1.25;
            }
        }

        &__content,
        &__sidebar {
            * + & {
                margin-top: 0;
                margin-left: $margin * 2;
            }
        }
    }

    @include respond-min($size-large) {
        &__container {
            width: $size-large;
        }
    }
}

.padded-block {
    background-color: $white;
    padding: $padding * 1.25;

    &--branded {
        background-color: $brand-color;
        color: $white;
    }

    @include respond-min($size-large) {
        padding: $padding * 2.5;
    }
}
