.training-single {
    &__info-block {
        background-color: $white-off;
        padding: $padding * 1.5;
        margin-top: $margin * 1.5;
    }

    &__info-items {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__info-item {
        position: relative;
        padding-left: $padding * 2.25;

        & + & {
            margin-top: $margin;
        }
    }

    &__info-icon {
        position: absolute;
        left: 0;
        top: 0;
    }
}
