.content-link {
    padding: $padding * 2;
    margin: $margin * 2 0;
    text-align: center;
    background-color: $white-off;
    text-decoration: none;
    display: block;

    &__image {
        margin: 0 auto $margin;
        width: 6.25rem;
        height: 6.25rem;
        flex-shrink: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    a {
        color: $brand-color;
    }

    h4 {
        margin: 0;
    }

    @include respond-min($size-medium) {
        display: flex;
        align-items: flex-start;
        text-align: left;
        background-image: url(../img/arrow-right.svg);
        background-position: calc(100% - #{$margin * 2}) center;
        background-repeat: no-repeat;

        &--nolink {
            background-image: none;
        }

        &__image {
            margin: 0 $margin * 1.5 0 0;
        }

        &__content {
            margin-right: $margin * 2.5;
        }
    }
}
