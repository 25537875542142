.post-single {
    &__info {
        font-style: italic;
        line-height: 1.5;

        &,
        a {
            font-size: $font-size-body;
        }
    }
}
