.header {
    background-color: $white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $header-height;
    border-top: 1px solid $grey-light;
    bottom: 0;
    left: 0;
    z-index: 10;

    &__toggle {
        display: none;

        &-trigger {
            position: absolute;
            right: 0;
            z-index: 1;
            width: 4.2rem;
            height: 4.2rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../img/menu-closed.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 1rem auto;
        }

        &:checked {
            ~ .header__toggle-trigger {
                left: 0;
                right: auto;
                background-image: url(../img/menu-open.svg);
            }

            ~ .navigation {
                visibility: visible;
                opacity: 1;
            }

            ~ .navigation .navigation__overlay {
                transform: translateX(0);
            }
        }
    }

    &__search {
        display: none;
    }

    @include respond-min($size-medium) {
        height: auto;
        padding-top: $margin * 1.5;
        position: static;
        flex-direction: column;

        &__toggle-trigger {
            display: none;
        }

        &__search {
            display: block;
            position: absolute;
            top: $margin;
            right: $margin;

            .form form {
                margin: 0;
            }
        }
    }
}
