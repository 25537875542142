$nav-transition-time: 0.3s;
$nav-transition-timing: cubic-bezier(0.47, 0.64, 0.25, 0.99);
$nav-text-color: #a6a6a6;

.navigation {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity $nav-transition-time $nav-transition-timing,
        visibility $nav-transition-time $nav-transition-timing;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background-color: rgba(#000, 0.64);
        z-index: 0;
    }

    &__overlay {
        width: 16rem;
        position: absolute;
        right: 0;
        height: 100%;
        overflow-y: auto;
        background-color: $white;
        z-index: 10;
        -webkit-overflow-scrolling: touch;
        transform: translateX(100%);
        transition: transform $nav-transition-time $nav-transition-timing;
    }

    &__items {
        margin: $margin * 2;
        padding: 0;
        list-style: none;
    }

    &__item {
        margin: 0;
        color: $nav-text-color;
        cursor: pointer;
        position: relative;

        & + & {
            margin-top: $margin * 2;
        }

        &.current_page_item {
            color: $brand-color;
        }

        &.menu-item-has-children::after {
            position: absolute;
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            padding: 0.5rem;
            background: url(../img/chevron-down.svg) center center no-repeat;
            background-size: 0.75rem auto;
            right: 0;
            top: 0;
            transition: transform 0.3s ease;
        }

        a {
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 2px;
            color: inherit;
        }

        &--open::after {
            transform: rotate(-180deg);
        }
    }

    .sub-menu {
        list-style: none;
        margin-top: $margin;
        padding: $padding * 1.5;
        background-color: $white-off;
        min-width: 10rem;
        -webkit-overflow-scrolling: touch;
        display: none;
    }

    &__item--open .sub-menu {
        display: block;
    }

    @include respond-min($size-medium) {
        height: auto;
        position: static;
        opacity: 1;
        visibility: visible;

        &::before {
            content: none;
        }

        &__overlay {
            position: static;
            width: 100%;
            height: auto;
            transform: none;
            overflow: visible;
        }

        &__items {
            margin: 0;
            margin-top: $margin * 2.5;
            display: flex;
            align-items: center;
            justify-content: center;

            > .current_page_item {
                border-color: $brand-color;
            }
        }

        &__item {
            position: relative;
            transition: color 0.3s ease;
            border-bottom: 4px solid transparent;

            & + & {
                margin: 0;
                margin-left: 4.25rem;

                .sub-menu & {
                    margin-top: $margin * 1.5;
                    margin-left: 0;
                }
            }

            a {
                display: block;
                padding-bottom: $padding * 2.5;
            }

            &.menu-item-has-children::after {
                right: -2rem;
            }

            &.current_page_item.menu-item-has-children::after {
                background-image: url(../img/chevron-down-black.svg);
            }

            &:hover,
            &.current_page_item {
                color: $black;
            }

            &:hover {
                .sub-menu {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(-50%, 0, 0);
                }

                &::after {
                    background-image: url(../img/chevron-down-black.svg);
                    transform: rotate(-180deg);
                }
            }
        }

        .sub-menu {
            display: block;
            opacity: 0;
            visibility: hidden;
            background-color: $white;
            position: absolute;
            margin: 0;
            top: 85%;
            left: 50%;
            z-index: 1;
            box-shadow: 0 0 16px rgba($black, 0.12);
            border-radius: $border-radius;
            transform: translate3d(-50%, -#{$margin}, 0);
            transition:
                opacity $nav-transition-time ease,
                visibility $nav-transition-time ease,
                transform $nav-transition-time ease;

            &::after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: $white;
                border-width: 8px;
                margin-left: -8px;
            }

            .navigation__item {
                padding-bottom: 0;

                a {
                    padding: 0;
                }

                & + & {
                    margin-top: $padding * 2.5;
                }
            }
        }
    }
}
