.training-call-to-action {
    text-align: center;

    h2,
    p {
        color: $white;
    }

    .button {
        display: block;
        margin-top: $margin * 2;
    }

    @include respond-min($size-large) {
        text-align: left;
    }
}
