/* stylelint-disable-line no-empty-source */
$size-small: 600px;
$size-medium: 900px;
$size-large: 1100px;
$size-xlarge: 1600px;

$margin: 1rem;
$padding: 1rem;

@mixin respond-min($size) {
    @media screen and (min-width: $size) {
        @content;
    }
}
