.page-header {
    padding: 0 $padding * 1.25;
    margin-bottom: $margin * 2.75;

    h1 {
        margin: 2.75rem 0 0;
        max-width: 48.75rem;
    }

    p {
        margin-top: 0.5rem;
    }

    @include respond-min($size-medium) {
        padding: 0;

        h1 {
            margin-top: 0;
        }
    }
}
