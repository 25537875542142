body .search-results {
    margin-top: $margin * 2;
}

.search-form {
    position: relative;

    &__icon {
        position: absolute;
        right: 0.9rem;
        bottom: 0.9rem;
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;

        .header__search & {
            bottom: 0.6rem;
            right: 0.6rem;
        }
    }

    @include respond-min($size-small) {
        .page__container & {
            width: 32.5rem;
        }
    }
}
