.form {
    form {
        margin-top: $margin * 2;
    }

    form > p {
        width: 100%;
    }

    input,
    select,
    textarea {
        width: 100%;
        border: 1px solid $grey-light;
        border-radius: 3px;
        padding: 0 $padding;
        margin-top: $margin * 0.5;
        height: 3rem;

        &.wpcf7-not-valid {
            border-color: $brand-color;
        }

        &:focus {
            outline: none;

            &:not([type="submit"]) {
                border-color: $grey;
                box-shadow: 0 2px 4px rgba($black, 0.1);
            }
        }
    }

    textarea {
        height: auto;
        padding: $padding;
    }

    select {
        background-color: transparent;
        appearance: none;
    }

    input {
        .header__search & {
            border-color: $grey-lighter;
            border-width: 2px;
            height: 2.5rem;
        }
    }

    &__select {
        .wpcf7-form-control-wrap {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 1rem;
                height: 0.625rem;
                top: 50%;
                right: $margin;
                transform: translateY(-50%);
                pointer-events: none;
                background: url(../img/chevron-down.svg) center center;
            }
        }
    }

    .wpcf7-response-output {
        text-align: center;
    }

    .wpcf7-validation-errors {
        border: none;
        color: $brand-color;
    }

    .wpcf7-mail-sent-ok {
        border: none;
    }

    @include respond-min($size-medium) {
        form {
            display: flex;
            flex-wrap: wrap;

            > p {
                width: calc(50% - #{$margin * 0.5});
                flex-grow: 1;
                margin-top: $margin * 2;
            }

            > p + p {
                margin-top: $margin * 2;

                &:nth-child(odd) {
                    margin-left: $margin;
                }

                &:last-of-type {
                    width: 100%;
                    margin-left: 0;
                    text-align: right;
                }
            }
        }
    }
}
