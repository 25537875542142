@font-face {
    font-family: 'Chalet New York Nineteen Eighty';
    src:
        url('../fonts/Chalet-NewYorkEighty.woff2') format('woff2'),
        url('../fonts/Chalet-NewYorkEighty.woff') format('woff');
}

$font-family-heading: 'Chalet New York Nineteen Eighty', Helvetica, Arial, sans-serif;
$font-family-body: 'Open Sans', Helvetica, Arial sans-serif;
$font-size-h1: 1.5rem;
$font-size-body: 0.875rem;
$font-size-small: 0.75rem;
$font-size-body-large: 1rem;
$font-size-h1-large: 2.5rem;

body {
    font-family: $font-family-body;
    font-size: 100%;
    color: $text-color-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family-heading;
    color: $text-color-heading;
    line-height: 1.25;
    letter-spacing: -0.05rem;
    margin: 0 0 $margin / 2 0;
    word-wrap: break-word;
}

h1 {
    font-size: $font-size-h1;
}

p,
a,
li {
    font-size: $font-size-body;
    line-height: 1.5;
    color: $text-color-body;
}

p {
    margin: 0;

    & + &,
    img + &,
    blockquote + & {
        margin-top: $margin * 2;
    }
}

blockquote {
    margin: 0;
    padding: 0;
    padding-left: $padding * 2;
    margin-top: $margin * 2;
    border-left: 4px solid $brand-color;

    &,
    p {
        font-family: $font-family-heading;
        font-weight: bold;
        font-size: 2rem;
        color: $grey-light;
        line-height: 2.875rem;
    }
}

ul {
    li {
        line-height: 1.75;
    }
}

.visuallyhidden {
    display: none;
}

@include respond-min($size-medium) {
    h1 {
        font-size: $font-size-h1-large;
    }

    p,
    a,
    li {
        line-height: 1.75;
        font-size: $font-size-body-large;
    }
}
