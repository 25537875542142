.footer {
    width: 100%;
    padding: $padding * 4.5 $padding * 1.25;
    background-color: $black-off;
    color: $grey-light;
    text-align: center;

    h1 {
        color: $grey-light;
    }

    &__wrapper {
        margin-top: $margin * 4.5;
    }

    &__info {
        a {
            display: block;
        }

        p,
        a {
            color: $grey-light;
            line-height: 2rem;
            margin: 0;
        }

        & + & {
            margin-top: $margin;
        }
    }

    @include respond-min($size-medium) {
        padding: $padding * 7 $padding * 1.25;

        &__wrapper {
            width: $size-medium;
            margin: 0 auto;
            margin-top: $margin * 4.25;
            display: flex;
            justify-content: space-between;
        }

        &__info {
            & + & {
                margin-top: 0;
            }
        }
    }
}
