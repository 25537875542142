.share {
    margin-top: $margin * 2;

    &__social-links {
        margin-top: $margin * 2;
    }

    &__social-link {
        text-decoration: none;

        & + & {
            margin-left: $margin;
        }
    }

    @include respond-min($size-medium) {
        margin-top: 0;

        * + & {
            margin-top: $margin * 1.25;
        }
    }
}
