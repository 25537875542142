@import 'member';

.team {
    padding: $padding * 2 0 $padding * 4;

    &__members {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__member {
        & + & {
            margin-top: $margin * 4;
        }
    }

    @include respond-min($size-medium) {
        &__members {
            display: flex;
            flex-wrap: wrap;
        }

        &__member {
            width: calc(50% - #{$margin});
            padding-bottom: $padding * 3.5;

            & + & {
                margin-top: 0;
            }

            &:nth-child(even) {
                margin-left: $margin * 2;
            }
        }
    }
}
