@import 'post-single';

.post {
    text-align: left;
    padding: $padding * 1.5;
    background-color: $white;
    box-shadow: 0 0 10px rgba($black, 0.05);
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.2s ease;

    h2 {
        margin-bottom: $margin / 2;
    }

    &__thumbnail {
        height: 215px;
        margin-bottom: $margin;
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $grey-lighter;
    }

    &__date {
        position: absolute;
        display: block;
        padding: $padding * 0.25 $padding * 0.5;
        background-color: $grey-dark;
        color: $white;
        border-radius: 2px;
        top: $margin * 0.75;
        left: $margin * 0.75;
        font-size: $font-size-small;
        font-weight: bold;
    }

    &__link {
        float: right;
        margin-top: $margin * 1.5;

        &-all {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        box-shadow: 0 0 10px rgba($black, 0.15);
    }
}
