.news-related {
    padding: $padding * 3.5 0;
    text-align: left;
    margin-top: $margin * 3.5;
    position: relative;

    .page__container--full & {
        margin: $margin * 3.5 $margin * 1.25 0;
    }

    &::before {
        content: '';
        width: 6.25rem;
        height: 4px;
        background-color: $grey-light;
        position: absolute;
        top: 0;
        left: 0;
    }
}
