@import 'news-latest';
@import 'news-related';

.news {
    &__items {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: $margin * 3;
        margin-bottom: $margin * 1;
    }

    &__item {
        width: 100%;

        & + & {
            margin-top: $margin * 1.5;
        }
    }

    &__nav {
        width: 100%;

        a + a {
            margin-top: $margin;
        }
    }

    @include respond-min($size-medium) {
        &__items {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            align-items: flex;
            margin-bottom: $margin * 5;
        }

        &__item {
            width: calc(33.333% - 21.33333px);
            margin: 0;
            margin-bottom: $margin * 2;

            & + & {
                margin-top: 0;
            }
        }

        &__nav {
            display: flex;
            justify-content: center;

            a + a {
                margin-left: $margin;
            }
        }
    }
}
