@import 'training-single';
@import 'call-to-action';

.training {
    display: block;
    background-color: $white;
    padding: $padding;
    overflow: hidden;
    text-decoration: none;
    transition: box-shadow 0.3s ease;

    h2 {
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
    }

    &__image {
        display: none;
    }

    &__link {
        display: block;
        float: right;
    }

    & + & {
        margin-top: $margin;
    }

    &:hover {
        box-shadow: 0 0 10px rgba($black, 0.15);
    }

    @include respond-min($size-small) {
        display: flex;
        align-items: flex-start;
        padding: $padding * 2;

        &__link {
            display: flex;
            align-self: center;
        }

        &__image {
            display: block;
            width: 7.125rem;
            height: 7.125rem;
            background-color: $grey-light;
            margin-right: $margin * 2;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__content {
            width: 23.125rem;

            .search-results & {
                width: 100%;
            }
        }

        &__link {
            align-self: center;
            margin-left: auto;
        }
    }
}
