.button,
.form .button,
.news__nav a {
    display: inline-block;
    border-radius: $border-radius;
    text-align: center;
    height: 4rem;
    border: 2px solid transparent;
    background-color: transparent;
    min-width: 11.25rem;
    width: 100%;
    line-height: 1.25;
    padding: $padding * 1.25 $padding;
    cursor: pointer;
    font-family: $font-family-heading;
    text-decoration: none;
    transition:
        background-color $transition-time $transition-timing,
        color $transition-time $transition-timing;

    &--primary,
    & {
        color: $brand-color;
        border-color: $brand-color;

        &:hover {
            background-color: $brand-color;
            color: $white;
        }
    }

    &--secondary {
        color: $white;
        border-color: $brand-color-secondary;
        background-color: $brand-color-secondary;

        &:hover {
            background-color: $brand-color-tertiary;
        }
    }

    &--tertiary {
        color: $white;
        border-color: $white;
    }

    * + & {
        margin-top: $margin / 2;
    }

    @include respond-min($size-medium) {
        width: auto;

        * + & {
            margin-top: $margin;
        }
    }
}
