.member {
    text-align: center;

    &__photo {
        width: 6.25rem;
        height: 6.25rem;
        margin: 0 auto $margin;
        border-radius: 6.25rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__name {
        margin-bottom: 0;
    }

    &__role {
        font-family: $font-family-heading;
        display: block;
    }

    &__info {
        max-width: $size-small / 2;
        margin: $margin auto 0;
    }

    &__linkedin {
        color: $brand-color;
        text-decoration: none;
        margin-top: $margin;
        display: block;
        line-height: 24px;

        span {
            margin-left: $margin / 4;
        }

        * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    @include respond-min($size-medium) {
        display: flex;
        align-items: flex-start;
        text-align: left;

        &__photo {
            width: 9.75rem;
            height: 9.75rem;
            flex-shrink: 0;
            margin: 0 0 $margin;
        }

        &__info {
            margin: $margin 0 0;
        }

        &__info,
        &__role {
            margin-top: $margin * 0.25;
        }

        &__linkedin {
            margin-top: $margin * 2;
        }

        &__content {
            margin-left: $margin * 2;
        }
    }
}
