.clients {
    &__logos {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__logo {
        height: 9rem;
        border: 2px solid $grey-lighter;
        background-size: 6rem auto;
        background-repeat: no-repeat;
        background-position: center;

        & + & {
            margin-top: $margin * 2;
        }
    }

    @include respond-min($size-small) {
        padding: $padding * 4 $padding * 6;

        &__logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__logo {
            width: calc(50% - #{$margin});
            margin-bottom: $margin * 2;

            & + & {
                margin-top: 0;
            }
        }
    }
}
