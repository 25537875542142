.icon {
    fill: $grey-dark;

    &--brand {
        fill: $brand-color;
    }

    &--grey-light {
        fill: $grey-light;
    }
}
