.contact {
    &__info {
        & + & {
            margin-top: $margin * 2.5;
        }

        p,
        a {
            color: $text-color-heading;
            margin: 0;
        }

        > a,
        p a {
            display: block;
        }
    }
}
