.filter {
    background-color: $white;
    padding: $padding * 1.5;
    margin-left: -$margin;
    margin-right: -$margin;

    &__label {
        font-weight: bold;
        color: $grey-dark;
        font-family: $font-family-heading;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__list {
        height: 0;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;

        &--active {
            font-weight: bold;
            color: $black;
        }

        &:hover {
            color: $black;
        }

        & + & {
            margin-top: $margin;
        }

        input[type="radio"] {
            display: none;
        }

        label {
            padding-left: 2rem;
            position: relative;
            z-index: 1;
        }
    }

    &__checkbox {
        margin-right: $margin;
        border: 1px solid $grey-light;
        background-color: $white;
        border-radius: 1.375rem;
        width: 1.375rem;
        height: 1.375rem;
        flex-shrink: 0;
        position: absolute;
        left: 0;
        top: 3px;

        .filter__item--active & {
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 0.75rem;
                background-color: $brand-color;
                position: absolute;
                top: 4px;
                left: 4px;
            }
        }
    }

    &__info {
        font-size: $font-size-body;
        margin-top: $margin;
        display: none;
        line-height: 1.5;
    }

    &__toggle-mobile {
        display: none;

        &:checked {
            ~ .filter__label svg {
                transform: rotate(180deg);
            }

            ~ .filter__list {
                height: auto;
                margin-top: $margin;
            }

            ~ .filter__info {
                display: block;
            }
        }
    }

    @include respond-min($size-medium) {
        background-color: transparent;
        padding: 0;
        margin-left: 0;
        margin-right: 0;

        &__label {
            display: none;
        }

        &__list {
            height: auto;
        }

        &__info {
            display: block;
        }

        label {
            cursor: pointer;
        }
    }
}
