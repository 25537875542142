.logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--nav-desktop {
        display: none;
    }

    &--nav-mobile {
        width: 9.75rem;
        margin-left: $margin * 1.5;

        .navigation__overlay & {
            width: 4rem;
            margin: 0 auto;
        }
    }

    @include respond-min($size-medium) {
        &--nav-mobile {
            display: none;
        }

        &--nav-desktop {
            display: block;
        }

        &--nav {
            display: none;
        }

        .header & {
            width: 16.5rem;
        }
    }
}
