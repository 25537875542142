.breadcrumbs {
    text-transform: uppercase;
    display: none;
    padding: $padding * 3.25 0;

    a,
    .breadcrumb_last {
        font-size: $font-size-body;
    }

    a {
        color: $grey;
        text-decoration: none;
    }

    .breadcrumb_last {
        font-weight: bold;
        color: $black;
    }

    [rel="v:child"] a,
    .breadcrumb_last {
        padding-left: $padding;
        margin-left: $margin / 2;
        background-image: url(../img/angle-right.svg);
        background-position: left center;
        background-size: auto $margin * 0.75;
        background-repeat: no-repeat;
    }

    @include respond-min($size-medium) {
        display: block;
    }
}
